import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter)

const routes = [
    {
        path: '/index',
        name: 'index',
        component: () => import("../view/index"),
        meta: {
            title: '团险服务'
        }
    },
    {
        path: '/supportConcept',
        name: 'supportConcept',
        component: () => import("../view/supportConcept"),
        meta: {
            title: '保障方案'
        }
    },
    {
        path: '/claimApplication',
        name: 'claimApplication',
        component: () => import("../view/claimApplication"),
        meta: {
            title: '理赔申请'
        }
    },
    {
        path: '/claimList',
        name: 'claimList',
        component: () => import("../view/claimList"),
        meta: {
            title: '理赔查询'
        }
    },
    {
        path: '/claimDetail',
        name: 'claimDetail',
        component: () => import("../view/claimDetail"),
        meta: {
            title: '理赔进度'
        }
    },
    {
        path: '/user',
        name: 'user',
        component: () => import("../view/user"),
        meta: {
            title: '信息完善'
        }
    },
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router