<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>


export default {
  name: 'App'
}
</script>

<style lang="scss" scoped>
html,body,#app {
  width: 100%;
  height: 100%;
}
/deep/ .van-tabbar-item--active{
  color: #e94709 !important;
}
</style>
