import Vue from 'vue';
import App from './App.vue';
import router from './router';
// import store from './store';


//清除浏览器默认样式
import '../node_modules/normalize.css/normalize.css'

//自适应rem
import '../node_modules/amfe-flexible/index.js'


//fastclick
import FastClick from 'fastclick';
FastClick.attach(document.body);

import '../static/css/reset.css';

// vant全局按需引入
import { Empty,Uploader,Picker,Popup,Icon,Form,Toast,Field,Button,Calendar,Step, Steps } from 'vant';
Vue.use(Uploader);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Form);
Vue.use(Toast);
Vue.use(Field);
Vue.use(Button);
Vue.use(Calendar);
Vue.use(Icon);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Empty);


Vue.config.productionTip = false;


new Vue({
  render: h => h(App),
  router,
  // store
}).$mount('#app')
